const scheduledTime = [
  {
    value: "1",
    label: "中餐",
  },
  {
    value: "2",
    label: "晚餐",
  },
];

export { scheduledTime };
